import './Loading.css'

const Loading = () => {
    return (
    <>
    <div class="loader"></div>
    
    </>
    );
}

export default Loading;



